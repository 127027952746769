* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  display: grid;
  grid-template-areas:
    "header header header"
    "sidebar content content";
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 250px 1fr; 
  height: 100dvh;
  transition: grid-template-columns 0.3s ease-in-out;
 
}
.sidebar-collapsed{
  grid-template-columns: 70px 1fr;
}


.content {
  grid-area: content;
  color: black;
  background-color: #ecefe3;
  padding:10px 20px;
  height:calc(100dvh - 60px);
  overflow-y: scroll;
}


