.sidebar {
  grid-area: sidebar;
  background-color: whitesmoke;
  color: #faf7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease-in-out;
  height: calc(100%);
  position:relative;
}
.not {
  height: calc(100dvh - 110px);
  overflow: auto;
  padding: 1rem;
}
.btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.button {
  height: 40px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 90px;
  border-radius: 50px;
  border: none;
  background-color: rgba(84, 16, 16, 0.908);
  color: white;
  font-size: medium;
}
.button:hover {
  background-color: rgba(150, 14, 14, 0.908);
}
.notOpenbut {
  height: 40px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: none;
  background-color: rgba(84, 16, 16, 0.908);
  color: white;
  font-size: medium;
}
.nav-btn {
  height: 48px;
  padding: 10px;
  color: rgb(18, 18, 18);
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  cursor: pointer;
}
.notOpen {
  height: 48px;
  padding: 10px;
  color: rgb(18, 18, 18);
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: all 0.3s ease-in-out;
  width: 48px;
  cursor: pointer;
}

.nav-btn .btnIcon {
  width: 20px;
  height: 20px;
  color: black;
}
.acti {
  border-radius: 50px;
  background-color: rgba(128, 128, 128, 0.377);
}
.notOpen:hover {
  background-color: #cec6bc9c;
  border-radius: 50px;
}
.nav-btn:hover {
  background-color: #cec6bc9c;
  border-radius: 50px;
}
.nav-btn:hover .btnIcon {
  color: black;
}
.disable-hover {
  pointer-events: none;
}
.sub-link {
  height: 48px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 10px;
  text-decoration: none;
  gap: 10px;
  transition: all 0.3s ease-in-out;
  color: black;
  margin-left: 30px;
}
.sub-link:hover {
  background-color: #cec6bc9c;
}
.active,
.active .btnIcon,
.sub-active {
  background-color: #060606f8;
  border-radius: 50px;
  color: white;
}
.notOpenactive {
  background-color: #060606f8;
  border-radius: 20px;
  color: white;
}

.toggleButton {
  position: absolute;
  right:0;
  z-index: 1000;
  color: black;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}
