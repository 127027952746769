.container {
  /* height: calc(100dvh - 100px); */
  height:100%;
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bbtn {
  min-height: 35px;
  width: 35px;
  border: none;
  border-radius: 20px;
  background-color: rgba(167, 164, 164, 0.612);
}
.heading {
  height: 40px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  background-color: #ecefe3;
  gap: 10px;
}
.heading > h3 {
  font-size: 20px;
}
.heading > h4 {
  font-size: 16px;
}
.driverDetails {
  height: calc(100dvh - 130px);
  width: 100%;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 10px;
}
.profile {
  height:100%;
  /* max-height: calc(100dvh - 130px); */
  background-color: rgba(128, 128, 128, 0.331);
  border-radius: 20px;
}
.detail {
  max-height: calc(100dvh - 130px);
  display:flex;
  flex-direction: column;
  gap:10px;
}
.de{
  flex:2.5;
  /* height */
  /* max-height: calc(70dvh - 50px); */
  /* overflow-y: auto; */
}

.de2 {
  flex:7.5;
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(128, 128, 128, 0.331);
  position: relative;
  overflow-y: auto;
}
.details {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.details2 {
  border-radius: 20px;
  padding: 1rem;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  background-color: rgba(128, 128, 128, 0.331);
}

.bttn {
  border-radius: 20px;
  height: 40px;
  padding: 5px 10px;
  border: none;
}
.activeBtn {
  border-radius: 20px;
  height: 40px;
  padding: 5px 10px;
  border: none;
  color: yellow;
  background-color: black;
}
