.contain {
  display: grid;
  grid-gap: 3em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.card {
  background-color: #fff;
  height: 150px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 16px;
  position:relative;
  font-size: large;
}
.card>h4{
  position:absolute;
}

.carddes {
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 50px;
}
