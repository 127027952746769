.tableContainer {
    padding: 0 10px;
    margin-top: 10px;
    max-height: calc(100dvh - 220px);
    width: 100%;
    overflow-y: auto;
    border-radius: 10px;
    background-color: rgba(243, 241, 241, 0.927);
    border: 2px solid rgba(128, 128, 128, 0.393);
  }
.table {
    width: 100%;
    border-spacing: 0;
    height: fit-content;
    table-layout: auto;
    background-color: rgba(243, 241, 241, 0.927);
  }
  .table thead {
    top: 0;
    z-index: 1;
    height: 50px;
    position: sticky;
    border-spacing: 0;
    border-bottom: none;
    box-shadow: 0px 2px 1px -1px rgba(10, 0, 0, 0.39);
  }
  
  .table tr:first-child th {
    border-top: 0;
  }
  .table td:first-child {
    width:200px;
    border-top: 0;
  }
  
  .table td {
    width: auto;
    height: 55px;
    text-align: center;
    border-bottom: 1px solid #a9a3a3cd;
  }
  .table th {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  td>input{
    font-size: 15px;
    height:100%;
    width:100%;
    padding:0 20px;
    background-color: #f0f0f006;
    outline:none;
    border:none;
  }
  .buton {
    border: 0;
    gap: 5px;

    padding: 8px 10px;
    border-radius: 20px;
    color: rgb(15, 75, 115);
    background-color: rgba(77, 171, 222, 0.448);
    cursor: pointer;
  }
 