.load {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 5px solid rgb(162, 160, 160);
    border-top-color: rgb(60, 60, 211);
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }