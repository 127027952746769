.rm {
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 3px);
  /* width: calc(100% - 1rem); */
}
.hc {
  gap: 20px;
  width: 100%;
  display: flex;
  margin-bottom: 0;
  margin-right: 20px;
  flex-direction: row;
}
.btnCon {
  gap: 4px;
  height: 40px;
  display: flex;
  position: relative;
}
.select,
.selectRefresh {
  height: 35px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: white;
  border-radius: 0 40px 40px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(137, 135, 135, 0.697);
}
.selectRefresh {
  border-radius: 40px 0 0 40px;
}
.ride {
  width: 100%;
  overflow-y: auto;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 1rem 0.3rem 1rem;
  max-height: calc(100dvh - 200px);
  background-color: rgba(243, 241, 241, 0.927);
  box-shadow: 0px 0px 2px 1px rgba(24, 0, 0, 0.284);
}
.dateRow {
  text-align: center;
  vertical-align: middle;
  background-color: #9b1f1f; /* Optional: Add a background color for better visibility */
  height: 50px; /* Adjust height as needed */
}

.centeredDate {
  text-align: center;
  vertical-align: middle;
  font-weight: bold; /* Make the date stand out */
}

.table {
  width: 100%;
  border-spacing: 0;
  height: fit-content;
  table-layout: auto;
  background-color: rgba(243, 241, 241, 0.927);
}
.table thead {
  top: 0;
  z-index: 1;
  height: 50px;
  position: sticky;
  border-spacing: 0;
  border-bottom: none;
  box-shadow: 0px 2px 1px -1px rgba(10, 0, 0, 0.39);
}

.table tr:first-child th {
  border-top: 0;
}
.table td:first-child {
  border-top: 0;
}

.table td {
  width: auto;
  height: 55px;
  text-align: center;
  border-top: 1px solid #a9a3a3cd;
  border-bottom: 1px solid #a9a3a3cd;
}
.table th {
  font-weight: bold;
  background-color: #f0f0f0;
}

.buton {
  border: 0;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  color: rgb(15, 75, 115);
  background-color: rgba(77, 171, 222, 0.448);
  cursor: pointer;
}
.cannotClick{
  background-color: rgba(0, 0, 0, 0.532);
  cursor: not-allowed;
  color:rgba(0, 0, 0, 0.194);
}
.buton:hover {
  color: #1a76d7;
}
.cannotClick:hover {
  color:rgba(0, 0, 0, 0.194);

}
.hc > h3 {
  font-size: x-large;
  margin-bottom: 10px;
}
.table td:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table td:first-child {
  box-shadow: 3px 0px 2px 0 rgba(34, 31, 31, 0.1);
}

.table tbody::-webkit-scrollbar {
  display: none;
}


.par {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%);
}
.modal {
  top: 100%;
  left: -125px;
  width: 400px;
  height: auto;
  display: flex;
  position: absolute;
  transform: scale(0);
  align-items: flex-start;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}
.modalactive {
  z-index: 1000;
  transform: scale(1);
}
.dateRow td:first-child {
  box-shadow: none !important;
}

.pagination {
  width: 200px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  align-self: flex-end;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 1px rgba(24, 0, 0, 0.284);
}
.pagination > span {
  font-size: bold;
}
.prev,
.next {
  width: 40px;
  border: none;
  height: 40px;
  background-color: white;
}
.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(8, 8, 8, 0.774);
}

.status{
  display:inline-block;
  height:fit-content;
  width:100px;
  background-color: rgb(189, 9, 9);
  padding:4px 10px;
  border-radius: 15px; 
  color: white;

}
.completed{
  background-color: rgb(10, 115, 10);
}
