.modalContainer {
  padding: 20px 0;
  min-height: 25%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background:rgb(249, 248, 248);
  border-radius: 20px;
  position: relative;
  gap:8px;
  color:rgb(19, 41, 19);
  font-size: small;
}

.bbt {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
}

.dateRange>label>input,.modalContainer>input{
  padding: 6px 0;
  border-radius: 10px;
  border: none;
}

.modalContainer>input{
    width: 70%;
    padding:6px 14px;
    font-size: medium;

}
.checkA{
  align-self: flex-start;
  padding-left:50px;
  display: flex;
  gap:5px;
}

.dateRange{
  width:70%;
  display:flex;
  justify-content: space-between;
}
.check{
  width:16px;
  height: 16px;
  border-radius: 50%;
}
.filterBtn{
  padding:4px 10px;
}
.status{
  width:50%;
  display: flex;
  justify-content: space-between;
}
.status>label{
  display: flex;
  gap:5px;
  font-size:14px;
}
.error{
  color:rgba(151, 4, 4, 0.998);
}