.new {
  height: calc(100dvh - 130px);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 15px;
  overflow-y: auto;
}
.heading {
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  justify-content: space-between;

  position:relative;
}
.heading > h2 {
  align-self: flex-start;
  text-align: left;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.button {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
}

.check {
  background-color: rgba(39, 93, 164, 0.633);
  color: white;
}
.cancel {
  background-color: rgba(255, 0, 0, 0.54);
  color: white;
}
.editButton {
  color: green;
}
.ima {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 200px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
  background-color: grey;
}
.ima>img{
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus {
  position: absolute;
  bottom: -20px;
  background-color: rgba(254, 254, 254, 0.957);
}

.ima > img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 20px;
}

.newT {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  /* background-color: yellow; */
}
.newT > p {
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: medium;
}
.statsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-top: auto;
}
.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120px;
  width: 50%;
  border-radius: 20px;
  color: yellow;
  background-color: rgba(7, 7, 7, 0.93);
}
.act {
  height: fit-content;
  padding: 4px 0;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  color: white;
  border-radius: 20px;
}
.yes{
    background-color: green;
}
.no{
    background-color: red;
}
.buttonActivate{
    border:none;
    padding:10px 0;
    width:100px;
    border-radius: 20px;
    margin-bottom: 10px;
}
