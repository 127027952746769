.message {
    position: absolute;
    top: 2px;
    right: 15px;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    opacity: 0;
    transform: translateX(100%) scaleX(0);
    transform-origin: right center;
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 1000;
  }
  
  .success {
    background-color: rgba(6, 101, 20, 0.964);
  }
  
  .error {
    background-color: rgba(203, 31, 8, 0.964);
  }
  
  .show {
    opacity: 1;
    transform: translateX(0) scaleX(1); 
  }
  