.header {
  height: 60px;
  grid-area: header;
  background: white;
  color: #0b0b0b;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpLogo {
  display: flex;
  font-weight: 600;
  font-size: large;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.cpLogo > span{
margin-left: 15px;
}

.header img {
  width: 40px;
  height: 40px;
  /* display: block; */
  align-self: center;
  justify-content: center;
  object-fit: fill;
  border-radius: 50%;
}

.header .ura {
  color: #0b0b0bd2;
}

.header .cab {
  color: #94a09eeb;
  margin-left: -10px;
}
.name {
  height: 40px;
  display: flex;
}
.lab {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 10px;
}
.lab > span {
  font-weight: 600;
}
.profile {
  display: flex;
  background-color: rgba(128, 128, 128, 0.463);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
