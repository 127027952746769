.whole {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100dvw;
  }
  .err {
    position: absolute;
    width: 100%;
    background: red;
    color: white;
    top: 0;
    text-align: center;
    padding: 5px 0;
    transition: all 3s ease-in-out;
  }
  .logi {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55vh;
    overflow: scroll;
    width: fit-content;
    gap: 20px;
    padding: 0 20px;
    border-top: 1px solid rgb(78, 77, 77);
    border-left: 1px solid rgb(139, 137, 137);
    border-radius: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .frm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .buton {
    font-weight: 600;
    font-size: medium;
    padding: 10px 20px;
    border-style: none;
    border-radius: 50px;
    background-color: rgb(238, 178, 105);
    transition: background-color 0.3s ease, padding 0.3s ease;
  }
  .buton:hover {
    background-color: rgb(242, 151, 15);
    padding: 10px 24px;
  }
  .pass,
  .select {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    border-radius: 50px;
    box-sizing: border-box;
    width: 300px; 
  }
  