.container {
    /* height:100%; */
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 5px;
    overflow: auto;
  }
  
  .buttons {
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  
  .buttons > button {
    height: 40px;
    width: 40px;
    border: 0;
    border-radius: 45px;
    font-size: medium;
  }

  .alb1 {
    width: 100%;
    /* height: 350px; */
    /* height: fit-content; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    text-align: center;
  }
  .fC {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr)); 
    border-radius: 20px;
    gap:10px;
  }
  
  .inf {
    border-radius: 20px;
    display: flex;
    padding: 4px;
    align-items: start;
    flex-direction: column;
    /* border-bottom: 1px solid #57692a3c; */
    border: 1px solid #57692a3c;
  }
  
  .verifyContainer {
      width:100%;
    display: flex;
    font-weight: 500;
    font-size: medium;
    align-items: center;
    justify-content: space-between;
  }
  .thatContain {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
  }
  .validation {
    height: 30px;
    color: rgb(19, 22, 22);
    border: none;
    border-radius: 20px;
    width: 80px;
  }
  .reject{
      color:red;
      background-color: rgba(255, 0, 0, 0.269);
  }
  .verify{
      color:green;
      background-color: rgba(0, 128, 0, 0.219);
  }
  
  .pdfPicker {
    width: 100%;
    height: 40px;
    padding: 4px;
    font-size: medium;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: rgba(115, 115, 101, 0.181);
  }
  .pdfPicker>span{
  padding-left:10px
  }
  .fullwidth {
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 128, 0, 0.74);
  }
  .hidden {
    display: none;
  }
  .fileSelect {
    padding: 10px 10px;
    border-radius: 50px;
    border: 0;
    /* background-color: rgba(128, 128, 128, 0.314); */
    background: rgba(18, 120, 237, 0.571);
    height: 30px;
    display: flex;
    align-items: center;
    color: blue;
    gap: 10px;
  }
  .removeFile {
    color: red;
    background: rgba(221, 27, 27, 0.291);
    padding: 10px 10px;
    border-radius: 50px;
    border: 0;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .selectedFileName {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .pdf {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 46dvw;
    background-color: rgba(0, 0, 0, 0.665);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .verified {
      display: flex;
      /* align-items: center; */
      height: 40px;
      /* position: relative; */
    }
  
  .verified>label {
    display: flex;
    align-items: center;
    height: 40px;
    position: relative;
    color: rgb(19, 19, 23);
    margin-right: 10px;
  }
  .icon {
    font-size: 20px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .iconVerified {
    color: rgb(28, 9, 169);
  }
  .notVerified {
    color: red;
  }
  .iconTick {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 10px;
  }
  .spa{
      height:25px;
      padding-right: 10px;
      border-radius: 15px;
  }
  
  .submitted{
    color:rgb(80, 79, 79);
    background-color: rgba(128, 128, 128, 0.354);
  }
  .rejected{
      color: red;
      background: rgba(221, 27, 27, 0.291);}
   .ver{
     /* color:rgb(5, 5, 92); */
     color:white;
     /* background-color: rgba(0, 0, 255, 0.375); */
     background-color: rgba(0, 0, 255, 0.913);
   }