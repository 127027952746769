.container {
  height: calc(100dvh - 80px);
  display: flex;
  flex-direction: column;
}
.tabContainer {
  padding: 0 10px;
  margin-top: 10px;
  max-height: calc(100dvh - 200px);
  /* width: 100%; */
  /* min-width: 700px; */
  overflow-y: auto;
  border-radius: 10px;
  background-color: rgba(243, 241, 241, 0.927);
  border: 2px solid rgba(128, 128, 128, 0.393);
}

.table {
  width: 100%;
  border-spacing: 0;
  height: fit-content;
  table-layout: auto;
  background-color: rgba(243, 241, 241, 0.927);
}

.table thead {
  top: 0;
  z-index: 1;
  height: 50px;
  position: sticky;
  border-spacing: 0;
  border-bottom: none;
  box-shadow: 0px 2px 1px -1px rgba(10, 0, 0, 0.39);
}

/* .table tr:first-child th {
  border-top: 0;
} */
.table td:first-child {
  border-top: 0;
}
/* .table tr th:nth-child(-n + 3) {
  border-top: 0;
  width: 200px;
} */

.table td {
    /* background-color: aqua; */
  /* width: auto; */
  width:400px;
  height: 55px;
  text-align: center;
  border-top: 1px solid #a9a3a3cd;
  border-bottom: 1px solid #a9a3a3cd;
}

.table th {
  font-weight: bold;
  background-color: #f0f0f0;
}
.table td:first-child {
    box-shadow: 3px 0px 2px 0 rgba(34, 31, 31, 0.1);
  }
.pagination {
  margin-top: 10px;
  width: 200px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  align-self: flex-end;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 1px rgba(24, 0, 0, 0.284);
}

.pagination > span {
  font-size: bold;
}

.prev,
.next {
  width: 40px;
  border: none;
  height: 40px;
  background-color: white;
}

.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(8, 8, 8, 0.774);
}
