.contain{
    height:100%;
}
.addContainer{
    display:grid;
    grid-template-columns: 1fr 1fr auto;
    gap:1rem;
    height:fit-content;
    padding:10px;
    align-items: end; 
}
.addContainer>label{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    padding:3px 15px;
}

.addContainer>label>input{
    height:35px;
    width:100%;
    margin-left: auto;
    border:none;
    font-size: medium;
    outline: none;
}

.addContainer button{
    height:40px;
    width: 150px;
    align-self: center;
}

.addContainer label:focus-within {
    border: 2px solid #6a6969;
}
.table {
    width: 100%;
    border-spacing: 0;
    height: fit-content;
    table-layout: auto;
    /* background-color: rgba(243, 241, 241, 0.927); */
  }
  .table thead {
    top: 0;
    z-index: 1;
    height: 50px;
    position: sticky;
    border-spacing: 0;
    border-bottom: none;
    box-shadow: 0px 2px 1px -1px rgba(10, 0, 0, 0.39);
  }
  
  .table tr:first-child th {
    border-top: 0;
  }
  .table td:first-child {
    width:200px;
    border-top: 0;
  }
  
  .table td {
    width: auto;
    height: 55px;
    text-align: center;
    border-bottom: 1px solid #a9a3a3cd;
  }
  .table th {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  td>input{
    font-size: 15px;
    height:100%;
    width:100%;
    padding:0 20px;
    background-color: #f0f0f006;
    outline:none;
    border:none;
  }
  .delete{
    padding:10px;
    border:none;
    border-radius: 10px;
    color:rgba(255, 0, 0, 0.738);
    background-color: rgba(255, 0, 0, 0.403);
  }
  .dcontainer {
    position: relative;
    padding: 0 10px;
    margin-top: 10px;
    max-height: calc(100dvh - 220px);
    width: 100%;
    overflow-y: auto;
    border-radius: 10px;
    background-color: rgba(243, 241, 241, 0.927);
    border: 2px solid rgba(128, 128, 128, 0.393);
  }

  @media (max-width: 768px) {
    .addContainer{
        display:grid;
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        gap:1rem;
        height:fit-content;
        padding:10px;
        align-items: end; 
    }
  }