.container {
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  display: flex;
  margin-right: 1rem;
  flex-direction: column;
  gap:10px;
}


.detailsContainer {
  height:100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
}

.selectedDetails{
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(94, 98, 94, 0.218);
  border-radius: 10px;
  gap:10px;
  padding:10px 1em;
  overflow: scroll;
  
}

.selectedDetails h3{
  font-size: 15px;
  margin: 0;
  padding:0;
}
.selectedDetails>span{
  /* height:30px; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(128, 128, 128, 0.494);
}

.bbtn{
  min-height:35px;
  width: 35px;
  border: none;
  border-radius: 20px;
  background-color: rgba(167, 164, 164, 0.612);
}
.heading{
  height:40px;
  position:sticky;
  top:0;
  left:0;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  z-index: 1000;
  background-color: #ecefe3;
  gap:10px
}
.heading>h3{
  font-size: 20px;
}
.heading>h4{
  font-size: 16px;
}