.container {
    height: calc(100dvh - 80px);
    width: calc(100% - 10px);

  }

  .contain{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    overflow-y: scroll;
    gap:10px;
  }

.bbtn{
    min-height:35px;
    width: 35px;
    border: none;
    border-radius: 20px;
    background-color: rgba(167, 164, 164, 0.612);
  }
  .heading{
    height:40px;
    position:sticky;
    top:0;
    left:0;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    z-index: 1000;
    gap:10px
  }
  .heading>h3{
    font-size: 20px;
  }

  .heading>h4{
    font-size: 16px;
  }

  .searchbtn{
    border:none;
    border-radius: 10px;
    height:40px;
    font-size: medium;
  }

  .drive{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap:10px;
    border-radius: 10px;
    background-color: rgba(167, 164, 164, 0.386);

  }
  
  .driverDetails{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(246, 246, 246, 0.956);
    position: relative;
  }
 .tripDetails{
  
 }
 .tripDetail{
   display: flex;
   gap:6px;
   flex-direction: column;
 }
 .selected{
  border:1px solid blue;
 }
 .assign{
  position:absolute;
  top:50%;
  height:35px;
  right:20px;
  transform: translateY(-50%);
  padding:0 15px;
 }
 .ddd{
    display: flex;
    align-self: center;
    font-size: 15px;
    color:green;
 }
