.container {
    display: flex;
    width: 100dvw;
    min-height: 100dvh;
    align-items: center;
    /* box-sizing: border-box; */
    justify-content: center;
    overflow-y: scroll;
  
    background-color: #f9f9f9;
  }
  .frm{
    background-color: rgb(235, 234, 234);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  }
  .form {
    gap: 20px;
    width: 40vw;
    /* height: 100dvh; */
    display: flex;
    min-width: 550px;
    overflow: scroll;
    position: relative;
    border-radius: 10px;
    padding-bottom: 5px;
    flex-direction: column;
    justify-content: center;
    
  }
  .h1 {
    font-weight: 600;
    align-self: center;
    margin: 10px 0 0 0;
    font-size: xx-large;
  }
  
  .line {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px 0 50px;
    justify-content: space-between;
  }
  .line>label{
    margin-right:0;
  }
  
  .input,
  .select {
    width: 100%;
    border: none;
    height: 40px;
    padding: 0 20px;
    font-size: small;
    border-radius: 30px;
    background: rgba(128, 128, 128, 0.25);
  }
  
  .inputContainer{
    position:relative;
    width: 60%;
    border: none;
    height: 40px;
  }
  
  .options{
    background-color: rgba(128, 128, 128, 0.24);
  }
  
  .submitButton {
    width: 40%;
    border: none;
    height: 40px;
    padding: 0 20px;
    font-size: small;
    color: #f9f9f9;
    align-self: center;
    border-radius: 30px;
    background-color: rgba(0, 0, 255, 0.591);
  }
  .submitButton:hover {
    background-color: rgba(0, 0, 255, 0.789);
  }
  .submitButton:disabled{
    cursor: not-allowed;
  }
  
  .input:focus,
  .select:focus {
    outline: 2px solid rgba(11, 90, 32, 0.738);
  }
  
  .toggleButton{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  
  .eye{
    width: 20px;
    height: 20px;
  }
  .mesCon{
    display:flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .message {
    height:20px; 
    font-style: italic;
    font-size: 14px;
    padding-left: 50px; 
    margin-bottom: 6px;
  }
  
  .success {
    color: rgba(6, 101, 20, 0.964);
  }
  
  .error {
    color: rgba(203, 31, 8, 0.964);
  }
  
  @media (max-width: 700px) {
    .container {
      height: auto;
    }
  
    .form {
      height: auto;
      width: 100dvw;
      overflow: scroll;
      padding: 10px 5px;
      min-width: 300px;
    }
    .inputContainer{
      background-color: rgba(0, 128, 0, 0);
      width:100%;
    }
  
    .line {
      gap: 10px;
      padding: 0 12px 0 12px;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .line > label {
      margin-left: 10px;
    }
  
    .input,
    .select {
      width: 100%;
    }
    .submitButton {
      background-color: rgba(12, 12, 162, 0.789);
    }
    .message{
      padding-left:20px
    }
  }
  