.pdf {
  width: 93%;
  height: 100%;
}
.button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: none;
  color: rgb(248, 248, 248);
  background-color: rgba(204, 154, 154, 0.519);
}
