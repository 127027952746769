.dm {
  height: calc(100% - 50px);
  box-sizing: border-box;
  position: relative;
}

.driverHeading {
  padding-right: 10px;
  font-size: x-large;
  color: #49532e;
}
.but {
  padding: 9px 10px 9px 20px;
  color: rgb(0, 0, 0);
  text-align: center;
  background-color: #20280ccb;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.but:hover {
  background-color: #293311;
}

.driver {
  display: flex;
  flex-direction: column;
}

.driver>form{
  display: flex;
  justify-content: space-between;
}

.driver > form > input {
  height: 40px;
  width: 300px;
  padding: 0 20px;
  border-radius: 20px;
  border: none;
  font-size: medium;
}

.dcontainer {
  padding: 0 10px;
  margin-top: 10px;
  max-height: calc(100dvh - 220px);
  width: 100%;
  overflow-y: auto;
  border-radius: 10px;
  background-color: rgba(243, 241, 241, 0.927);
  border: 2px solid rgba(128, 128, 128, 0.393);
}
.dcontainer > table {
  width: 100%;
  border-spacing: 0;
  height: fit-content;
  table-layout: auto;
  background-color: rgba(243, 241, 241, 0.927);
}
.table thead {
  top: 0;
  z-index: 1;
  height: 50px;
  position: sticky;
  border-spacing: 0;
  border-bottom: none;
  box-shadow: 0px 2px 1px -1px rgba(10, 0, 0, 0.39);
}

.table tr:first-child th {
  border-top: 0;
}
.table td:first-child {
  border-top: 0;
}
.table tr th:nth-child(-n + 3) {
  border-top: 0;
  width: 200px;
}

.table td {
  width: auto;
  height: 55px;
  text-align: center;
  border-top: 1px solid #a9a3a3cd;
  border-bottom: 1px solid #a9a3a3cd;
}

.table th {
  font-weight: bold;
  background-color: #f0f0f0;
}

.buton {
  border: 0;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  color: rgb(15, 75, 115);
  background-color: rgba(77, 171, 222, 0.448);
  cursor: pointer;
}

.buton:hover {
  color: #1a76d7;
}
.hc > h3 {
  font-size: x-large;
  margin-bottom: 10px;
}
.table td:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table td:first-child {
  box-shadow: 3px 0px 2px 0 rgba(34, 31, 31, 0.1);
}

.table tbody::-webkit-scrollbar {
  display: none;
}

.pagination {
  margin-top: 10px;
  width: 200px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  align-self: flex-end;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 1px rgba(24, 0, 0, 0.284);
}

.pagination > span {
  font-size: bold;
}

.prev,
.next {
  width: 40px;
  border: none;
  height: 40px;
  background-color: white;
}

.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(8, 8, 8, 0.774);
}

.status {
  margin-left: 5px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  
}
.activated {
  background-color: green;
  height:15px;
  width:15px;
  border-radius: 50%;
}
.deactivated {
  background-color: red;
  height:15px;
  width:15px;
  border-radius: 50%;
}
.vercon{
  display: flex;
  align-items: center;
  justify-content:center ;
  gap:5px;
}
.vercon>span{
  position: relative;
}

.certificate{
  color:blue;
  font-size:19px;
}
.check{
  font-size: 12px;
  position:absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color:white;
}

.on{
  color: green;
}
.off{
  color: red;
}